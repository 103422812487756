import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/images/fha-logo-nav.svg";
import { useNavigate } from "react-router-dom";
const Navbar = ({ navItems, action, actionName, isEmpty }) => {
  const user = localStorage.user ? JSON.parse(localStorage.user) : {};
  const compliance = localStorage.compliance ? JSON.parse(localStorage.compliance) : {};
  const navigate = useNavigate();
  // const logo =
  //   "https://res.cloudinary.com/dzquobiiy/image/upload/v1723102728/fha-logo-nav_v2xtz0.svg";
  const navIcon =
    "https://res.cloudinary.com/dzquobiiy/image/upload/v1723102733/nav-icon_p7uo9a.svg";
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  // console.log(navItems);
 const login = ()=>{
  navigate("/login");
 }
 const expressInterest=()=>{  navigate("/interest-form");}
//  const logout = ()=>{
//   localStorage.clear();
//   navigate("/");
//  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-full flex pt-[16px] sm:pt-[0px] justify-center relative">
      <div className="absolute bg-[#3D454E] border-b-[1px] border-[#AFE155] w-full top-0 right-0 left-0 z-[-2] h-[124px]"></div>
      <div className="flex w-full h-[124px] px-[20px] max-w-[1240px] justify-between">
        <div className="flex w-full items-center relative">
          <div className="absolute max-w-[192px] pt-[10px] w-[120px] h-[120px] lg:w-full lg:h-full max-h-[192px]">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="flex ms-[110px] lg:ms-[200px] flex-col">
            <span className="font-[500] leading-[31px] text-[16px] lg:text-[24px] text-[#FFFFFF]">
              FHA
            </span>
            <span className="font-[500] leading-[31px] text-[16px] lg:text-[24px] text-[#B0E155]">
              RENEWED HOPE
            </span>
            <span className="font-[500] leading-[31px] text-[16px] lg:text-[24px] text-[#FFFFFF]">
              ESTATES
            </span>
          </div>
        </div>
       <div className="flex w-full items-center justify-end gap-[34px]">
       {navItems && !isEmpty && <div className="flex items-center gap-[34px]">
        {navItems.map((item, index) => (
          <a className="font-[500] whitespace-nowrap text-[16px] text-[#FAFAFA]" href={`${item.id}`} key={index}>{item.name}</a>
       ))}
        </div>}
        <div className="flex items-center">
          {!user._id && !isEmpty && <div className="flex items-center gap-[18px]"><span onClick={login} className="border-[#E6E6E6] cursor-pointer border rounded-[8px] px-[32px] py-[16px] text-[#FFFFFF] whitespace-nowrap font-[500] text-[16px]">Login</span><span onClick={expressInterest} className="bg-[#fff] rounded-[8px] px-[32px] py-[16px] cursor-pointer text-[#3D454E] whitespace-nowrap font-[500] text-[16px]">Get Started</span></div> }
          {user._id && !isEmpty && <div className="flex items-center gap-[8px]"><div className="w-[32px] h-[32px] bg-[#fff] rounded-[32px]"></div><span className="font-[500] text-[16px] text-[#FAFAFA]">Hello, {user.first_name}</span></div>}
        </div>
       </div>
      </div>
    </div>
  );
};

export default Navbar;
